@import '../../../../../node_modules/@cian/ui-kit-design-tokens/colors/semanticPalette.css';

.providers {
  padding: 0 10px;
  display: flex;
  width: 100%;
  container-name: newbuilding-banner-providers;
  container-type: inline-size;
}

.img {
  margin-left: -10px;
  display: inline-block;
  width: 40px;
  height: 40px;
  background: var(--stroke-control-disabled, #e4e9f6);
  border: 1px solid var(--stroke-control-default, #c9d1e5);
  border-radius: 50%;
  object-fit: cover;
}

@container newbuilding-banner-providers (width < 170px) {
  .img:nth-child(6) {
    display: none;
  }
}

@container newbuilding-banner-providers (width < 140px) {
  .img:nth-child(6) {
    display: none;
  }
  .img:nth-child(5) {
    display: none;
  }
  .img:nth-child(4) {
    display: none;
  }
}
