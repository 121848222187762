.list {
  margin-top: var(--unit_6);
  margin-bottom: var(--unit_6);
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: var(--unit_6) var(--unit_4);
}

.row {
  grid-column: 1 / 5;
}
