@import '../../../../../node_modules/@cian/ui-kit-design-tokens/colors/semanticPalette.css';

.section {
  padding: 24px;
  background: var(--surface-neutral-default, #f3f6ff);
  border-radius: 8px;
}

.header {
  display: flex;
  justify-content: space-between;
}

.recommendations {
  padding: 16px 0;
}
