.link {
  padding: 12px;
  display: flex;
  width: 40px;
  height: 40px;
  color: var(--text-main-default, #0468ff);
  background-color: var(--control-main-secondary-default, rgba(4, 104, 255, 0.1));
  border: none;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
}
