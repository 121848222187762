.labels {
  position: absolute;
  z-index: 1;
  top: var(--unit_2);
  left: var(--unit_2);
}

@media (min-width: 641px) {
  .labels {
    top: var(--unit_3);
    left: var(--unit_3);
  }
}
