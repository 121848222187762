@import '../../../../../node_modules/@cian/ui-kit/colors/variables.module.css';

.card {
  all: unset;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  cursor: pointer;
  background: white;
  border-radius: 12px;
  box-shadow: inset 0 0 0 1px var(--gray10_100, #e8e9ec);
}

.image {
  display: block;
  overflow: hidden;
  max-width: 100%;
  background-color: var(--gray10_100, #e8e9ec);
  border-top: 1px solid var(--gray10_100, #e8e9ec);
  border-left: 1px solid var(--gray10_100, #e8e9ec);
  border-right: 1px solid var(--gray10_100, #e8e9ec);
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  flex: 0 0 140px;
  object-fit: cover;
}

.content {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.title > * {
  overflow: hidden;
  text-overflow: ellipsis;
}

.details {
  display: inline-block;
}

.reviews {
  display: inline-block;
  float: left;
}

.status {
  display: inline;
}

.status:nth-child(2)::before {
  padding: 0 4px;
  display: inline;
  font-weight: bold;
  content: '･';
}
