.container {
  position: absolute;
  left: 50%;
  bottom: 16px;
  overflow: hidden;
  width: 84px;
  transition:
    transform 300ms,
    padding-left 300ms;
  transform: translateX(-50%);
}

.scroller {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  list-style: none;
  transition: transform 300ms;
}
