.container {
  display: flex;
  flex-direction: column;
  border-radius: var(--unit_3);
}

.info {
  margin: 8px 0 12px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.skeleton {
  position: relative;
  width: 100%;
  height: 16px;
  background-color: #f3f6ff;
  border-radius: var(--unit_1);

  &.image {
    width: 100%;
    aspect-ratio: 320 / 280;
    flex: 1;
    border-radius: var(--unit_3) var(--unit_3) 0 0;
  }

  &.short {
    width: 45%;
    height: 22px;
  }

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    background-image: linear-gradient(
      90deg,
      rgb(255, 255, 255, 0) 0%,
      rgb(255, 255, 255, 0.8) 50%,
      rgb(255, 255, 255, 0) 100%
    );
    background-repeat: no-repeat;
    background-size: 50% 100%;
    animation-name: shimmer;
    animation-duration: 1s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
}

@keyframes shimmer {
  0% {
    background-position-x: -100%;
  }

  70% {
    background-position-x: 200%;
  }

  100% {
    /* Значение такое же, как у предыдущего шага, чтобы сымитировать задержку между итерациями анимации */
    background-position-x: 200%;
  }
}
