.container {
  position: relative;
  display: flex;
  overflow-x: hidden;
  flex-direction: column;
  gap: var(--unit_0);
  text-decoration: none;

  & > span {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.image {
  margin-bottom: var(--unit_2);
  width: 100%;
  border-radius: var(--unit_1);
  aspect-ratio: 164 / 90;
  object-fit: cover;
}

.price {
  display: flex;
  flex-direction: column;
  gap: var(--unit_0);
}

.description {
  overflow: hidden;
  font-size: var(--fontSize_14px);
  line-height: var(--lineHeight_20px);
  white-space: nowrap;
  text-decoration: none;
  text-overflow: ellipsis;
  color: var(--decorative-theme-dark);

  &.two-rows {
    /* stylelint-disable value-no-vendor-prefix */
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    /* stylelint-enable value-no-vendor-prefix */
    line-height: var(--fontSize_22px);
    white-space: initial;
    text-overflow: initial;
  }
}

.favorite {
  position: absolute;
  z-index: 1;
  top: 8px;
  right: 8px;
}
