.custom-container {
  --internal-padding: 16px;

  padding: 0 0 var(--internal-padding);
}

.custom-content {
  flex-grow: 1;
  pointer-events: none;
}

.title,
.footer-button {
  padding: 0 var(--internal-padding);
}

.title {
  margin-top: 8px;
}
