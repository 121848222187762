.wrapper {
  position: relative;
  user-select: none;
}

.newbuilding-recommendations-banners-container {
  margin: 0;
  padding: 0;
  display: flex;
  overflow: scroll hidden;
  height: 100%;
  list-style: none;
  cursor: pointer;
  pointer-events: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  column-gap: var(--unit_4);
}

.newbuilding-recommendations-banners-container::-webkit-scrollbar {
  display: none;
}

.newbuilding-recommendations-banners-container li {
  flex: 0 0 calc((100% - var(--unit_4) * 4) / 5);
  min-width: 0;
}

.newbuilding-recommendations-banners-container li > div {
  height: 100%;
}
