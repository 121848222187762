.container {
  display: flex;
  flex-direction: row;
  gap: 4px;
}

.underground {
  display: flex;
  align-items: center;
  gap: 4px;
  min-width: 0;
  white-space: nowrap;
}

.name {
  overflow: hidden;
  text-overflow: ellipsis;
}
