.container {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.under-construction {
  color: #7a7a7a;
}

.icon {
  margin-right: 6px;
  display: inline-block;
}

.name {
  overflow: hidden;
  text-overflow: ellipsis;
}
