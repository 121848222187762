.price-discount {
  display: flex;
  gap: var(--unit_2);
  align-items: center;
}

.old-price {
  font-size: var(--fontSize_14px);
  line-height: var(--lineHeight_20px);
  text-decoration: line-through;
  color: var(--gray60_100);
  text-decoration-color: var(--accent-positive-primary);
  text-decoration-thickness: 2px;
}
