.container {
  position: relative;
  display: block;
  width: 12px;
  height: 12px;
  flex-shrink: 0;
  opacity: 0.6;
  transition: opacity 300ms;
}

.container::after {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 7px;
  height: 7px;
  content: '';
  background: white;
  border: 1px solid rgba(21 34 66 / 0.1);
  border-radius: 9px;
  transition: transform 300ms;
  transform: translate(-50%, -50%);
}

.container--size-m::after {
  transform: translate(-50%, -50%) scale(0.75);
}

.container--size-s::after {
  transform: translate(-50%, -50%) scale(0.5);
}

.container--active {
  opacity: 1;
}
