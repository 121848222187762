@import '../../../../../node_modules/@cian/ui-kit-design-tokens/colors/semanticPalette.css';

.banner {
  position: relative;
  padding: 20px;
  display: flex;
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: var(--text-primary-default);
  background-color: var(--brand-pink, #ffe1ff);
  border-radius: 12px;
  flex-flow: column wrap;
  container-name: newbuilding-banner;
  container-type: inline-size;
  gap: 16px;
}

.link {
  position: absolute;
  inset: 0;
  opacity: 0;
}

.brand-pink {
  background: var(--brand-pink, #ffe1ff);
}

.primary-basic {
  color: var(--text-inverted-default, white);
  background: var(--primary_100, #0468ff);
}

.brand-peach {
  background: var(--brand-peach, #ffdcc8);
}

.gradient-linear-primary-brand-pink {
  background: linear-gradient(180deg, #c1e8fd 0%, #ffe1ff 100%);
}

.gradient-linear-diagonal-primary-brand-pink {
  background: linear-gradient(147deg, #c1e8fd 20%, #ffe1ff 98.36%);
}

.gradient-linear-diagonal-primary-brand-peach {
  background: linear-gradient(135deg, #ffdcc8 0%, #c1e8fd 100%);
}

.content {
  display: flex;
  flex-direction: row-reverse;
  flex-grow: 1;
  gap: 8px;
}

.description {
  flex-grow: 1;
}

.labels {
  padding-bottom: 16px;
  display: flex;
  gap: 8px;
}

.title {
  padding-bottom: 8px;
}

.illustration {
  flex: 0 0 56px;
}

.footer {
  display: flex;
  width: 100%;
  flex-basis: 40px;
  align-items: center;
  justify-content: space-between;
}

.footer > div {
  width: 100%;
}

/* ширина контейнера учитывает только доступную шаирину контента, т.е. width-padding */
@container newbuilding-banner (width < 280px) {
  .description {
    flex-grow: 0;
  }
  .content {
    display: flex;
    flex-direction: column;
  }
}
